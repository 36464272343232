<template>
  <div class="freespins-container pt-2 pt-lg-4 px-0 col-12 mx-auto">
    <div class="row mx-0 mt-4 justify-content-center">
      <button class="path-item mt-2">
        <router-link :to="{ name: 'home' }" class="text-white"> Главная</router-link>
      </button>
      <span class="path-icon mx-2"> » </span>
      <button class="path-item mt-2">Фриспины</button>
    </div>

    <div class="row mx-0 mt-2 justify-content-center">
      <h1 class="text-white mx-2">Фриспины</h1>
    </div>

    <div class="row mx-0 mb-5">
      <div class="col-12 col-md-9 col-lg-12 mx-auto px-0 pt-3">
        <inner-menu :activePage="'freespins'"></inner-menu>
        <div class="row mx-0 py-3 freespin-container">
          <div class="col-12 col-md-11 px-2 px-lg-0 py-0 py-lg-4 mx-auto">
            <b-list-group class="col-12 mx-auto pr-0" v-if="freespinsData.length">
              <b-list-group-item v-for="(freespin, idx) in freespinsData" :key="idx"
                                 class="freespin-item py-4 px-3 px-lg-5 mb-4 text-white border-0">
                <b-row>
                  <div class="col-4 col-sm-2 col-md-3 col-lg-2 col-xl-1">
                    <img :src="$config.storageUrl +'/'+ freespin.game.picture.replace(/^\/|\/$/g, '')"
                         alt="VolgaSlot онлайн Казино"
                         class="mx-auto freespin-img">
                  </div>
                  <div class="col-8 col-sm-10 col-md-9 col-lg-10 col-xl-11">
                    <span>{{ freespin.game.name }}</span><br>
                    <span>Вам доступно {{ freespin.count }} фриспинов</span>
                    <div class="col-12 px-0">
                      <button class="btn pink-btn px-5 w-100 mt-3" @click="launchUrl(freespin)">Играть</button>
                    </div>
                  </div>
                </b-row>
              </b-list-group-item>
            </b-list-group>
            <div class="text-center" v-else>
              <span class="text-white">У вас пока нет фриспинов</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9 mx-auto coins-bg mb-3"></div>
    </div>
  </div>
</template>

<script>
import InnerMenu from '@/components/InnerMenu'

export default {
  name: 'Freespins',
  components: {
    InnerMenu
  },
  computed: {
    freespinsData() {
      return this.$store.getters['getFreespins']
    }
  },
  methods: {
    launchUrl(freespin) {
      const gameName = freespin.game.name.split(' ').join('-')
      return this.$router.push({
        name: 'TheGame',
        params: {
          gameName: gameName,
          gameId: freespin.game.id,
          game: freespin.game,
          roundId: freespin.id,
          provider: freespin.system,
          isLive: freespin.game.isLive,
          restoring: false
        }
      })
    }
  }
}
</script>

<style scoped>
.freespin-img {
  width: 75px;
  max-width: 100%;
  max-height: 80px;
  object-fit: contain;
  border-radius: 7px;
}

.freespin-container {
  background-color: #09235f;
  min-height: 50px;
}

.freespin-item {
  background-color: #051949;
  border-radius: 7px;
}

.freespin-item:hover {
  box-shadow: 0 0 6px 5px #051949 !important;
}
</style>
