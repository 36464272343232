<template>
  <div v-if="userData">
    <div class="row px-0 color-white" v-if="histories && histories.length">
      <div v-for="(history, index) in histories" :key="index" class="col-12 history pt-2 pb-2 cursor-pointer">
        <div class="winner row d-flex justify-content-between align-items-center" @click="showDateHistory(index)">
          <div class="pay col-3 col-md-3 text-center h-100">
            <span v-if="history.method_type == 'card'">Карта</span>
            <span v-else-if="history.method_type == 'sbp'">СБП</span>
            <span v-else>Кошелек</span>
          </div>
          <div class="pay col-4 col-md-3 text-center h-100">
            {{ history.amount }}
          </div>
          <div class="pay text-center col-5 col-md-3 pay-status" v-if="mode === 'withdraw'">
            <button type="button" class="text-center mx-auto" :class="getStatusInfo(history.status, 'type')">
              {{ getStatusInfo(history.status, 'text') }}
            </button>
            <a class="withdraw-info ml-3"
               v-if="canShowModal(getStatusInfo(history.status, 'type'), history.destination)"
               @click="openWaitingModal">
              <i class="fa fa-info-circle"></i>
            </a>
          </div>
          <div class="pay text-center col-5 col-md-3 pay-status" v-else>
            <button type="button" class="text-center mx-auto confirmed">
              Подтверждено
            </button>
          </div>
        </div>
        <div class="history-desc" :class="{ active: index === activeHistory }">
          <span class="d-block">Сумма транзакции: {{ history.amount }}</span>
          <span class="d-block">Дата операции: {{ history.date }}</span>
        </div>
      </div>
    </div>
    <div class="py-lg-4 mx-0 col-12 col-lg-10 col-xl-8 mx-auto px-0" v-else>
      <div class="text-center color-white">
        <h5>История отсутствует</h5>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: "History",
  props: {
    histories: Array || null,
    mode: String
  },
  data() {
    return {
      activeHistory: null,
      statuses: {
        1: {
          type: 'pending',
          name: 'В ожидании',
        },
        2: {
          type: 'support_confirmed',
          name: 'Суппорт подтвердил',
        },
        3: {
          type: 'confirmed',
          name: 'Подтверждено',
        },
        4: {
          type: 'canceled',
          name: 'Отменено',
        },
        5: {
          type: 'canceled_returned',
          name: 'Возвращено',
        },
      }
    }
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    },
    getEnableWaitingWithdrawModal() {
      return this.$store.getters.getEnableWaitingWithdrawModal
    }
  },
  methods: {
    canShowModal(status, destination) {
      if (status === 'pending' && this.getEnableWaitingWithdrawModal == 1 &&
          destination !== 'piastrixwallet' && destination !== 'freekassawallet') {
        return true
      }
      return false
    },
    openWaitingModal() {
      this.$root.$emit('bv::show::modal', 'AfterWithdrawModal')
    },
    getStatusInfo(status, type) {
      if (type === 'type') {
        return this.statuses[status].type

      }

      if (type === 'text') {
        return this.statuses[status].name
      }
    },
    showDateHistory(value) {
      if (this.activeHistory != value) {
        this.activeHistory = value;
      } else {
        this.activeHistory = null;
      }
    }
  },
}

</script>

<style scoped>
.history {
  min-height: 40px;
  border-bottom: 1px solid #585858;;
  font-size: 12px;
}

.pay button {
  border-radius: 34px;
  min-width: 100px;
  height: 35px;
  border: unset !important;
  color: #ffff;
  font-size: 12px;
}

.pay .confirmed {
  background-color: #3d9b2a !important;
}

.pay .pending {
  background-color: #2d61f0 !important;
}

.pay .support_confirmed {
  background-color: #307c0d !important;
}

.pay .canceled_returned {
  background-color: #daa208 !important;
}

.pay .canceled {
  background-color: #f02d2d !important;
}

.history-desc {
  display: none;
  text-align: start;
}

.active {
  display: block;
  animation: fadeIn 1s ease forwards;
}

.withdraw-info {
  font-size: 25px;
  color: #9a0807;
}

.withdraw-info i {
  color: #9a0807;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 992px) {
  .history,
  .pay {
    font-size: 10px;
  }
}

</style>