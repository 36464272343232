<template>
    <div class="row mx-0 inner-menu justify-content-between" v-if="activePage">
        <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4 current-page-container"
             v-if="activePage === 'account' && userData && userDataFetched">
            <div class="row mx-0">
                <div class="col-12 col-lg-5 px-0">
                    <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.account" alt="account">
                </div>
                <div class="col-12 col-lg-7 px-0">
                    <span class="text-white float-lg-left">Аккаунт</span>
                </div>
            </div>
        </div>
        <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4" v-else-if="userData && userDataFetched">
            <router-link :to="{ name: 'account' }"
                         class="px-0 mx-0 mx-md-3 d-md-block mt-0 mb-2 ">
                <div class="row mx-0">
                    <div class="col-12 col-lg-5 px-0">
                        <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.account" alt="account">
                    </div>
                    <div class="col-12 col-lg-7 px-0">
                        <span class="text-white float-lg-left">Аккаунт</span>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4 current-page-container"
             v-if="activePage === 'deposit'">
            <div class="row mx-0">
                <div class="col-12 col-lg-5 px-0">
                    <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.deposit" alt="deposit">
                </div>
                <div class="col-12 col-lg-7 px-0">
                    <span class="text-white float-lg-left">Депозит</span>
                </div>
            </div>
        </div>

        <div class="col-4 col-md-2 px-0 text-center inner-menu-item py-2 px-0 px-lg-4" v-else>
            <router-link :to="{ name: 'deposit' }"
                         class="px-0 mx-0 mx-md-3 d-md-block mt-0 mb-2 ">
                <div class="row mx-0">
                    <div class="col-12 col-lg-5 px-0">
                        <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.deposit" alt="deposit">
                    </div>
                    <div class="col-12 col-lg-7 px-0">
                        <span class="text-white float-lg-left">Депозит</span>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4 current-page-container" v-if="activePage === 'withdraw'">
            <div class="row mx-0">
                <div class="col-12 col-lg-5 px-0">
                    <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.withdraw" alt="withdraw">
                </div>
                <div class="col-12 col-lg-7 px-0">
                    <span class="text-white float-lg-left">Вывод</span>
                </div>
            </div>
        </div>
        <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4" v-else>
            <router-link :to="{ name: 'withdraw' }" class="px-0 mx-0 mx-md-3 d-md-block mt-0 mb-2 ">
                <div class="row mx-0">
                    <div class="col-12 col-lg-5 px-0">
                        <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.withdraw" alt="withdraw">
                    </div>
                    <div class="col-12 col-lg-7 px-0">
                        <span class="text-white float-lg-left">Вывод</span>
                    </div>
                </div>
            </router-link>
        </div>


          <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4 current-page-container"
               v-if="withdrawMethods && (withdrawMethods.vip && withdrawMethods.vip.length > 0) && activePage === 'vipWithdraw'">
                <div class="row mx-0">
                    <div class="col-12 col-lg-5 px-0">
                        <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.withdraw" alt="vipWithdraw">
                    </div>
                    <div class="col-12 col-lg-7 px-0">
                        <span class="text-white float-lg-left">VIP Вывод</span>
                    </div>
                </div>
            </div>
            <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4" v-else-if="withdrawMethods && (withdrawMethods.vip && withdrawMethods.vip.length > 0) && activePage != 'vipwithdraw'">
                <router-link :to="{ name: 'vipwithdraw' }" class="px-0 mx-0 mx-md-3 d-md-block mt-0 mb-2 ">
                    <div class="row mx-0">
                        <div class="col-12 col-lg-5 px-0">
                            <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.withdraw" alt="vipWithdraw">
                        </div>
                        <div class="col-12 col-lg-7 px-0">
                            <span class="text-white float-lg-left">VIP Вывод</span>
                        </div>
                    </div>
                </router-link>
            </div>



        <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4 current-page-container"
             v-if="activePage === 'history' && userData">
            <div class="row mx-0">
                <div class="col-12 col-lg-5 px-0">
                    <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.history" alt="withdraw">
                </div>
                <div class="col-12 col-lg-7 px-0">
                    <span class="text-white float-lg-left">История вывода</span>
                </div>
            </div>
        </div>
        <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4"  v-else-if="userData">
            <router-link :to="{ name: 'history' }" class="px-0 mx-0 mx-md-3 d-md-block mt-0 mb-2 ">
                <div class="row mx-0">
                    <div class="col-12 col-lg-5 px-0">
                        <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.history" alt="history">
                    </div>
                    <div class="col-12 col-lg-7 px-0">
                        <span class="text-white float-lg-left">История вывода</span>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4 current-page-container"
             v-if="activePage === 'depositHistory' && userData">
            <div class="row mx-0">
                <div class="col-12 col-lg-5 px-0">
                    <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.history" alt="depositHistory">
                </div>
                <div class="col-12 col-lg-7 px-0">
                    <span class="text-white float-lg-left">История Пополнения</span>
                </div>
            </div>
        </div>
        <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4" v-else-if="userData">
            <router-link :to="{ name: 'depositHistory' }" class="px-0 mx-0 mx-md-3 d-md-block mt-0 mb-2 ">
                <div class="row mx-0">
                    <div class="col-12 col-lg-5 px-0">
                        <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.history" alt="depositHistory">
                    </div>
                    <div class="col-12 col-lg-7 px-0">
                        <span class="text-white float-lg-left">История Пополнения</span>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4 current-page-container"
             v-if="activePage === 'messages' && userData && userDataFetched">
            <div class="row mx-0">
                <div class="col-12 col-lg-5 px-0">
                    <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.messages" alt="messages">
                </div>
                <div class="col-12 col-lg-7 px-0">
                    <span class="text-white float-lg-left">Сообщение</span>
                </div>
            </div>
        </div>
        <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4" v-else-if="userData && userDataFetched">
            <router-link :to="{ name: 'messages' }" class="px-0 mx-0 mx-md-3 d-md-block mt-0 mb-2 ">
                <div class="row mx-0">
                    <div class="col-12 col-lg-5 px-0">
                        <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.messages" alt="messages">
                    </div>
                    <div class="col-12 col-lg-7 px-0">
                        <span class="text-white float-lg-left">Сообщение</span>
                    </div>
                </div>
            </router-link>
        </div>

      <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4 current-page-container"
           v-if="activePage === 'freespins' && userData && userDataFetched">
        <div class="row mx-0">
          <div class="col-12 col-lg-5 px-0">
            <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.freespins" alt="freespins">
          </div>
          <div class="col-12 col-lg-7 px-0">
            <span class="text-white float-lg-left">Фриспины</span>
          </div>
        </div>
      </div>
      <div class="col-4 col-md-2 text-center inner-menu-item py-2 px-0 px-lg-4" v-else-if="userData && userDataFetched">
        <router-link :to="{ name: 'freespins' }" class="px-0 mx-0 mx-md-3 d-md-block mt-0 mb-2 ">
          <div class="row mx-0">
            <div class="col-12 col-lg-5 px-0">
              <img class="icon mx-2 d-lg-block float-lg-right mt-lg-1 menu-icon" :src="menuIcons.freespins" alt="freespins">
            </div>
            <div class="col-12 col-lg-7 px-0">
              <span class="text-white float-lg-left">Фриспины</span>
            </div>
          </div>
        </router-link>
      </div>


    </div>
</template>

<script>
    export default {
        props: ['activePage'],
        data() {
            return {
                menuIcons: {
                    account: require('@/../public/images/icons/menu-icons/account.svg'),
                    deposit: require('@/../public/images/icons/menu-icons/deposit.svg'),
                    history: require('@/../public/images/icons/menu-icons/history.svg'),
                    withdraw: require('@/../public/images/icons/menu-icons/enter.svg'),
                    messages: require('@/../public/images/icons/message.svg'),
                    freespins: require('@/../public/images/icons/freespins.svg'),
                }
            }
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            },
            userDataFetched() {
                return this.$store.getters['userDataFetched']
            },
            siteSettings () {
                return this.$store.getters.getMainSettings
            },
            withdrawMethods() {
              return this.$store.getters['getAvilWithdrawMethods']
            },
        },
      watch: {
        $route (to) {
          if (to && to.params && to.params.placeName) {
            this.activePage = to.params.placeName
          }
        }
      }
    }
</script>

<style scoped>
    .current-page-container {
        background-color: #0a37a1;
    }

    .inner-menu {
        background-color: #051949;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
    .menu-icon{
        width: 25px;
        height: 25px;
    }
    @media only screen and (max-width: 1200px) {
        .menu-icon{
        width: 18px;
        height: 18px;
    }
    }
</style>
