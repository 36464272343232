import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store'

import Home from '@/components/Home'
import ForgotPassword from '@/components/user/ForgotPassword'
import Account from '@/components/user/Account'
import Messages from '@/components/user/Messages'
import Deposit from '@/components/payment/Deposit'
import Withdraw from '@/components/payment/Withdraw'
import WithdrawHistory from '@/components/payment/WithdrawHistory'
import DepositHistory from '@/components/payment/DepositHistory'
import Bonuses from '@/components/Bonuses'
import Statistics from '@/components/Statistics'
import TheGame from '@/components/games/TheGame'
import Terms from '@/components/Terms'
import UserLevels from "@/components/UserLevels";
import ThePoker from "../components/games/ThePoker";
import Chat from "../components/elements/Chat";
import VipWithdraw from "@/components/payment/VipWithdraw";
import Freespins from "@/components/user/Freespins";

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            name: 'home',
            path: '/',
            props: true,
            component: Home
        },  
        {
            name: 'forgotPassword',
            path: '/forgotPassword',
            component: ForgotPassword,
            beforeEnter: (to, from, next) => {
                if (Store.getters['userData']) {
                    return next('/')
                }
                return next()
            }
        },
        {
            name: 'account',
            path: '/account',
            component: Account,
            props: true
        },
        {
            name: 'messages',
            path: '/messages',
            component: Messages
        },
        {
            name: 'freespins',
            path: '/freespins',
            component: Freespins
        },
        {
            name: 'deposit',
            path: '/deposit',
            component: Deposit
        },
        {
            name: 'withdraw',
            path: '/Withdraw',
            component: Withdraw
        },
        {
            name: 'vipwithdraw',
            path: '/vipWithdraw',
            component: VipWithdraw
        },
        {
            name: 'history',
            path: '/withdraw-history',
            component: WithdrawHistory
        },
        {
            name: 'depositHistory',
            path: '/deposit-history',
            component: DepositHistory
        },
        {
            name: 'bonuses',
            path: '/Bonuses',
            component: Bonuses
        },
        {
            name: 'chat',
            path: '/chat',
            component: Chat
        },
        {
            name: 'statistics',
            path: '/statistics',
            component: Statistics
        },
        {
            name: 'TheGame',
            path: '/Game/:gameName',
            props: true,
            component: TheGame,
            beforeEnter: (to, from, next) => {
                if (Store.getters['userData'] === null || typeof Store.getters['userData'] === 'undefined') {
                    Store.dispatch('fetchUserData').then((resp) => {
                        if (resp.userData) {
                            return next()
                        }
                        return next('/')
                    })
                }
                return next()
            }
        },
        {
            name: 'ThePoker',
            path: '/Poker',
            props: true,
            component: ThePoker,
            beforeEnter: (to, from, next) => {
                if (Store.getters['userData'] === null || typeof Store.getters['userData'] === 'undefined') {
                    Store.dispatch('fetchUserData').then((resp) => {
                        if (resp.userData) {
                            return next()
                        }
                        return next('/')
                    })
                }
                return next()
            }
        },
        {
            name: 'terms',
            path: '/Terms',
            component: Terms
        },
        {
            name: 'UserLevels',
            path: '/Levels',
            props: true,
            component: UserLevels,
            meta: {
                title: 'Уровни игроков'
            }
        }
        // {
        //     name: 'LiveGames',
        //     path: '/LiveGames',
        //     component: LiveGames
        // },
        // {
        //     name: 'TheLiveGame',
        //     path: '/LiveGame/:gameName',
        //     props: true,
        //     component: TheLiveGame,
        //     beforeEnter: (to, from, next) => {
        //         if (Store.getters['userData'] === null || typeof Store.getters['userData'] === 'undefined') {
        //             Store.dispatch('fetchUserData').then((resp) => {
        //                 if (resp.userData) {
        //                     return next()
        //                 }
        //                 return next('/')
        //             })
        //         }
        //         return next()
        //     }
        // }
    ]
})

