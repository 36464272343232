<template>
    <div id="app">
        <div class="mx-auto main-container px-0">
           <div class="row m-0 justify-content-center">
                <div class="col-sm-12 col-lg-12 col-xl-10 px-0 mw-xl-80">
                    <the-header></the-header>
                    <div class="content-container" :class="[isGamePage ? 'gameBg' : '']">
                        <router-view></router-view>
                    </div>
                    <the-footer v-if="$route.path !== '/chat'"></the-footer>
                </div>
           </div>
        </div>
        <FlashMessages></FlashMessages>
        <popup></popup>
        <AliveGamePopup></AliveGamePopup>
        <FreeRoundSelector v-if="!userHasAliveGame && allowFreeRoundPopup != false"></FreeRoundSelector>
        <AfterWithdrawModal></AfterWithdrawModal>
    </div>
</template>

<script>
    import TheHeader from '@/components/TheHeader'
    import TheFooter from '@/components/TheFooter'
    import Popup from '@/components/Popup'
    import FlashMessages from '@/components/FlashMessages'
    import AliveGamePopup from "./components/games/AliveGamePopup";
    import FreeRoundSelector from './components/games/FreeRoundSelector';
    import AfterWithdrawModal from "@/components/payment/AfterWithdrawModal"

    export default {
        name: 'app',
        data() {
          return{
              currentPath : '',
              allowAliveGamePopup: false,
              allowFreeRoundPopup: false
          }
        },
        components: {
            TheHeader,
            TheFooter,
            FlashMessages,
            Popup,
            AliveGamePopup,
            FreeRoundSelector,
            AfterWithdrawModal

        },
        computed: {
            isGamePage() {
                return this.$route.name === 'TheGame'
            },
            freeRound() {
              return this.$store.getters.freeRound
            },
            userHasAliveGame() {
                const userData = this.$store.getters.userData
                if (!userData) {
                    return false
                }
                const aliveGames = this.$store.getters.aliveGames
                return !!(aliveGames && aliveGames.length)
            },
            userData(){
               return this.$store.getters.userData
            }
        },
        methods: {
            isMobile() {
                return this.$_config.environment.mobile
            },
        },
        watch: {
        async $route() {
              const collapseBtn = document.getElementById('navbar-toggler')
              const collapseState = collapseBtn.getAttribute('aria-expanded')
              if (collapseState === 'true') {
                collapseBtn.click()
              }
                this.currentPath = this.$route.name;

                let chatBlock = document.getElementById("tawkchat-container");
                if (chatBlock) {
                    if (this.isGamePage && this.isMobile()) {
                        chatBlock.style.display = "none"
                    } else {
                        chatBlock.style.display = "block"
                    }
                }

                if (!this.isGamePage) {
                    await this.$store.dispatch("checkForAliveGame")
                    await this.$store.dispatch("checkUserFreeRound")
                }

            },
            alive: {
            deep: true,
            handler(newVal) {
                if (newVal) {
                  this.allowAliveGamePopup = true
                }
            }
            },
            freeRound: {
            deep: true,
                handler(newVal) {
                    if (newVal) {
                      this.allowFreeRoundPopup = true
                    }
                }
            }
        },

        mounted(){
            if(!this.isGamePage){
                this.$store.dispatch('checkUserFreeRound')
            }
        }
    };

</script>

