<template>
    <div class="game-item position-relative mb-3 mx-1 d-table" @click.prevent.stop="gotoGameView">
        <div class="w-100 mw-100 vertical-middle d-table-cell game-img-container"
             v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }">
            <img :data-src="game.picture" :src="game.picture" class="game-img mw-100 mh-100 w-100 h-100" alt="game image">
            <div class="game-name">
              <div class="text-center g-name text-white">{{game.name}}</div>
            </div>
        </div>

        <div class="overlay position-absolute w-100 h-100 text-center hidden-bg cursor-pointer">
            <div class="game-info h-100 w-100">
                <div class="play-btn-container d-table w-100">
                    <div class="d-table-cell" style="vertical-align: middle;">
                        <button class="btn pink-btn play-btn w-75 mx-auto d-none d-lg-inline-block border-0"
                                @click.prevent.stop="gotoGameView">
                            ИГРАТЬ
                        </button>
                    </div>
                </div>

<!--                <div class="games-name-container d-table w-100">-->
<!--                    <h4 class="game-title d-table-cell text-white"> {{game.name}} </h4>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['game'],
        name: 'GameItemPreview',
        computed: {
            userData() {
                return this.$store.getters['userData']
            },
        },
        methods: {
            gotoGameView() {
                if (!this.userData) {
                    return  this.$bvModal.show('AuthModal')
                }
                this.$router.push({
                    name: 'TheGame',
                    params: {
                        gameName: this.game.name,
                        gameId: this.game.id,
                        game: this.game,
                        provider: this.game.system,
                        isLive: this.game.isLive,
                        restoring: false
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .game-name{
      position: absolute;
      height: 30px;
      background-color: #07193a;
      padding: 4px 8px;
      line-height: 24px;
      border-radius: 4px;
      width: 90%;
      left: 5%;
      bottom: -15px;
      z-index: 999;
      box-shadow: 0 0 4px 1px #017bc0;
      overflow: hidden;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .game-title {
        vertical-align: middle;
    }

    .play-btn-container {
        height: 70%;
    }

    .games-name-container {
        height: 30%;
        background-color: black;
    }

    .game-img-container {
        height: 200px;
    }

    .game-img-container img{
        object-fit: contain;
        border: 1px solid #017bc0;
        border-radius: 5px;
        box-shadow: 0 0 2px 2px #017bc0;
    }
    .game-item {
        max-width: 200px;
        height: 200px;
        background-color: #07193a;
    }

    .overlay {
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        background-size: 100% 100%;
    }

    .game-item .hidden-bg {
        display: none;
    }

    .game-item:hover > .hidden-bg {
        display: block;
        overflow: hidden;
    }
    .game-img {
        object-fit: contain;
    }

    @media only screen and (max-width: 1200px) {
        .game-img {
            max-width: 200px !important;
        }
    }
</style>
