<template>
    <div class="row mx-0 w-100 navbar-container">

        <div class="mobile-logo">
            <router-link :to="{ name: 'home' }" aria-label="home">
                <img class="header-logo d-lg-none px-1" :src="logo" alt="VolaSlot">
            </router-link>
        </div>
        <div class="col-7 col-md-12 px-0 menu-container">
            <b-navbar toggleable="lg" type="dark" variant="custom" class="main-menu">
                <b-navbar-toggle target="nav-collapse" id="navbar-toggler"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <div class="col-0 col-lg-2 col-xl-2 px-0" :class="!userData ? 'col-md-6': 'col-md-4'">
                        <b-navbar-brand class="mx-0 px-0 d-none d-lg-block">
                            <router-link :to="{ name: 'home' }">
                                <img class="header-logo px-1" :src="logo" alt="VolgaSlot">
                            </router-link>
                        </b-navbar-brand>
                    </div>

                    <!--menu list-->
                    <div class="col-12 col-xl-10 px-md-3 menu-list " v-if="userDataFetched">
                        <b-navbar-nav class="justify-content-between main-manu">

                            <b-navbar-nav v-if="!userData" class="d-lg-none px-2">
                                <b-nav-item>
                                    <a @click="showAuthModal(1)" class="btn pink-btn login-btn w-100">
                                        Войти
                                    </a>
                                </b-nav-item>
                                <b-nav-item>
                                    <a @click="showAuthModal(0)" class="btn pink-btn  reg-btn w-100" >
                                        Регистрация
                                    </a>
                                </b-nav-item>
                            </b-navbar-nav>

                            <b-navbar-nav v-else class="mb-3 d-lg-none">
                                <div class="auth-btns row mx-0 w-100">
                                    <div class="col-12 text-center">
                                        <router-link :to="{ name: 'account' }" class="text-white">
                                            <span> {{userData.login}}</span><br>
                                            <span class="text-white font-weight-bold">Баланс : {{userData.balance}} {{userData.currency_name}}</span>
                                        </router-link>
                                    </div>
                                    <div class="col-md-12 px-0 mt-3 text-center">
                                        <button class="btn pink-btn login-btn w-75" @click.prevent="logout">
                                            Выйти
                                        </button>
                                    </div>
                                </div>
                            </b-navbar-nav>

                            <b-nav-item>
                                <router-link :to="{ name: 'home' }" active-class="active-page" exact
                                             class="ml-md-3 d-lg-block text-center mobile-item ml-3">
                                    <span class="menu-item">Казино</span>
                                </router-link>
                            </b-nav-item>

                            <b-nav-item>
                                <router-link :to="{ name: 'deposit' }" active-class="active-page" exact
                                             class="ml-md-3 d-lg-block text-center mobile-item ml-3">
                                    <span class="menu-item">Депозит</span>
                                </router-link>
                            </b-nav-item>
                            <b-nav-item>
                                <router-link :to="{ name: 'withdraw' }" active-class="active-page" exact
                                             class="ml-md-3 d-lg-block text-center mobile-item ml-3">
                                    <span class="menu-item">Выплаты</span>
                                </router-link>
                            </b-nav-item>

                            <b-nav-item >
                                <router-link :to="{ name: 'statistics' }" active-class="active-page"  exact
                                             class="ml-md-3 d-lg-block text-center mobile-item ml-3">
                                    <span class="menu-item">Инфо</span>
                                </router-link>
                            </b-nav-item>

                            <b-nav-item >
                                <router-link :to="{ name: 'bonuses' }" active-class="active-page"  exact
                                             class="ml-md-3 d-lg-block text-center mobile-item ml-3">
                                    <span class="menu-item">Бонусы</span>
                                </router-link>
                            </b-nav-item>

                            <b-nav-item v-if="userData">
                                <router-link
                                        :to="{ name: 'TheGame', params: {gameName:'KronosWheel', gameId:1, provider: 'WheelOfFortune'}}"
                                        active-class="active-page" exact
                                        class="ml-md-3 d-lg-block text-center mobile-item ml-3">
                                    <span class="menu-item">Колесо</span>
                                </router-link>
                            </b-nav-item>
                            <b-nav-item v-else>
                                <a @click="showAuthModal(1)" active-class="active-page"  exact
                                   class="ml-md-3 d-lg-block text-center mobile-item">
                                <span class="menu-item ml-3 ml-md-0">Колесо</span>
                            </a>
                            </b-nav-item>
                            <b-nav-item>
                                <router-link :to="{ name: 'UserLevels' }" active-class="active-page" exact
                                      class="ml-md-3 d-lg-block text-center mobile-item ml-3">
                                    <span class="menu-item">Уровни</span>
                                </router-link>
                            </b-nav-item>
                            <b-nav-item v-if="!userData" >
                                <a @click="showAuthModal(0)" active-class="active-page" exact
                                            class="px-0 mx-0 mx-lg-3 pt-1  auth-block d-none d-lg-block text-center">
                                <span class="menu-item ml-3 ml-md-0">Регистрация</span>
                            </a>
                        </b-nav-item>
                        <b-nav-item v-if="!userData" >
                            <a @click="showAuthModal(1)" active-class="active-page"  exact
                                            class="enter-account auth-block pt-1 text-white text-center d-none d-lg-block">
                                <span class="menu-item ml-3 ml-md-0">Войти</span>
                            </a>
                        </b-nav-item>
                        <b-nav-item v-if="userData" class="active-user pl-2">
                            <router-link :to="{ name: 'account'}" class="enter-account text-white text-center d-none d-lg-block">
                                <span> {{userData.login}} </span>
                                <span class="text-white font-weight-bold">| Баланс : {{userData.balance}} {{userData.currency_name}}
                                </span>
                            </router-link>
                        </b-nav-item>
                        <b-nav-item v-if="userData" class="active-user pl-2">
                            <a @click.prevent="logout" class="enter-account logout text-white text-center d-none d-lg-block">
                                Выйти
                            </a>
                        </b-nav-item>
                        </b-navbar-nav>
                        
                    </div>
                </b-collapse>
            </b-navbar>
        </div>
         <AuthModal v-if="!userData" :activeTab="activeTab"></AuthModal>
    </div>

</template>

<script>
    import AuthModal from "./modals/AuthModal";

    export default {
        name: 'RwvHeader',
        components: {
            AuthModal
        },
        data() {
            return {
                gamesSearchText: '',
                headerLogo: true,
                collapsed: true,
                activeTab:1,
                logo: require('@/../public/images/logos/logo.svg'),
            }
        },
        computed: {
            siteSettings () {
                return this.$store.getters.getMainSettings
            },
            userData() {
                return this.$store.getters['userData']
            },
            balance() {
                return this.$store.getters['getBalance']
            },
            userDataFetched () {
                return this.$store.getters.userDataFetched
            }
        },
        methods: {
            logout() {
                this.axios.get(
                    this.$_config.baseUrl + '/Api/logout'
                ).then(() => {
                    this.$store.dispatch('removeUserData')
                    window.location.reload(true)
                }).catch(err => {
                    console.log(err)
                })
            },
            showAuthModal(tab){
                this.activeTab = tab;
                this.$bvModal.show('AuthModal')
            },
            gotoGameView() {
                this.$router.push({
                    name: 'TheGame',
                    params: {
                        provider: 'wheeloffortune',
                        gameId: 1
                    }
                })
            },
            isMobile() {
                return this.$_config.environment.mobile
            }
        },
        mounted() {
            this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
                if (isJustShown && collapseId === 'nav-collapse') {
                    this.collapsed = true
                    document.querySelector('.main-container').classList.add('disable-scroll')
                } else {
                    this.collapsed = false
                    document.querySelector('.main-container').classList.remove('disable-scroll')
                }
            })
        },
    }
</script>

<style scoped>
    .disable-scroll {
        overflow: hidden;
    }
    .auth-block{
        border: #0ce8ae solid 1px;
        width: 180px;
        border-radius: 30px; 
    }
    .nav-link{
        padding: unset!important;
    }
    .nav-link > a{
        padding-bottom:10px;
    }
    .nav-link > .auth-block{
        padding-bottom:unset;
        height: 35px;
    }
    .mobile-item span{
        font-weight: 500;
    }
    .nav-link > .auth-block span{
        color: #0ce8ae;
    }
    .enter-account:hover, .enter-balances:hover {
        border-bottom: 1px solid white;
    }
    .main-manu .nav-link:hover > a,
    .active-page {
        border-bottom: 1px #0ce8ae solid;
    }
    .main-manu .nav-link:hover > .auth-block .menu-item{
        color: #fff!important;
    }
    .main-manu .nav-link:hover > a .menu-item{
        color: white;
    }
    .main-manu .nav-link:hover > .auth-block{
       background-color: #08b287;
       
    }
    .active-page .menu-item{
        color: white!important;
    }
    .navbar-toggler {
        border: none !important;
        outline: none !important;
    }
    @media only screen and (max-width: 1470px) {
        .auth-block{
          width: 120px;
        }     
    }
    @media only screen and (max-width: 1300px) {
        .auth-block{
          width: 100px;
        }     
    }
    @media only screen and (max-width: 999px) {
        .auth-block{
            width: 120px;
        }     
    }
    @media only screen and (max-width: 991px) {
        .main-menu {
            position: absolute;
            z-index: 20;
            top: -28px;
        }
        .menu-item{
            font-size: 15px!important;
        }
        .nav-link{
            margin-top: 20px;
        }
        .main-menu .navbar-collapse {
            position: absolute;
            top: 54px;
            width: 100vw;
            left: 0;
            background: #0c1525b3 !important;
        }
        .menu-list {
            width: 50vw !important;
            height: 100%;
            min-height: 100vh;
            padding-top: 20px;
            background-image: url("../../public/images/backgrounds/volga_bg.webp") !important;
            background-size: cover !important;
        }
    }
</style>
<style scoped>
    .navbar-container {
        align-items: center;
        border-bottom: 1px solid #172037;
        box-shadow: -4px 13px 6px -1px rgba(0,0,0,.2), 0 2px 4px -1px rgba(0,0,0,.122);
        margin-bottom: 20px;
    }
    .header-logo {
        max-width: 190px;
    }
    .menu-item {
        color: #828f9a;
        font-size: 14px;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }
    .pulse {
        animation: pulse 3s infinite;
        animation-timing-function: linear;
    }

    .pulse:hover {
        animation: unset;
    }

    @media only screen and (max-width: 991px) {
        .mobile-logo {
            z-index: 34;
        }
        .navbar-container {
            height: 60px;
            position: fixed;
            z-index: 33;
            background-image: url("../../public/images/backgrounds/volga_bg.webp") !important;
            background-size: cover !important;
        }
        .menu-container {
            position: fixed;
        }
        .header-logo {
            max-width: 130px;
            position: absolute;
            right: 25px;
            top: 15px;
        }
    }

    @media (min-width: 991px) and (max-width: 1199px) {
        .menu-list {
            position: absolute;
            left: 0;
            top: 68px;
            text-align: center;
        }
    }
</style>
